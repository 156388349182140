import React from "react";

function SuperAdminDashboard() {
  return (
    <div>
      <p>Hello SuperAdminDashboard</p>
    </div>
  );
}

export default SuperAdminDashboard;
