import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { getData } from "../authentication/axiosInstance";
import "./LeadDetails.css";

function LeadDetails() {
  const location = useLocation();
  const receivedData = location.state.Leadgen_id;
  const [details, setDetails] = useState({});

  useEffect(() => {
    const leadgenData = async () => {
      try {
        const response = await getData(`users/leads/${receivedData}`);
        console.log("lead details with id", response.data);
        if (response.success === "SUCCESS") {
          setDetails(response.data);
        }
      } catch (error) {
        console.log("error", error.response?.data.message || error.message);
      }
    };
    leadgenData();
  }, [receivedData]);
  return (
    <div className="ad-details-container">
      <h1 className="title">Ad Details</h1>

      <section className="ad-information">
        <h2>Ad Information</h2>
        <p>
          <strong>Ad ID:</strong> {details.ad_id}
        </p>
        <p>
          <strong>Ad Name:</strong> {details.ad_name}
        </p>
        <p>
          <strong>Ad Set ID:</strong> {details.adset_id}
        </p>
        <p>
          <strong>Ad Set Name:</strong> {details.adset_name}
        </p>
        <p>
          <strong>Campaign ID:</strong> {details.campaign_id}
        </p>
        <p>
          <strong>Campaign Name:</strong> {details.campaign_name}
        </p>
        <p>
          <strong>Created Time:</strong>
          {new Date(details.created_time).toLocaleString()}
        </p>
      </section>

      <section className="field-details">
        <h2>Field details</h2>
        <ul>
          {details?.field_data?.map((field, index) => (
            <li key={field._id}>
              <strong>{field.name}:</strong> {field.values.join(", ")}
            </li>
          ))}
        </ul>
      </section>

      <section className="additional-information">
        <h2>Additional Information</h2>
        <p>
          <strong>Form ID:</strong> {details.form_id}
        </p>
        <p>
          <strong>ID:</strong> {details.id}
        </p>
        <p>
          <strong>Is Organic:</strong> {details.is_organic ? "Yes" : "No"}
        </p>
        <p>
          <strong>Platform:</strong>{" "}
          {(details.platform === "ig" || "fb") && "Facebook"}
        </p>
      </section>
    </div>
  );
}

export default LeadDetails;
