import React, { useEffect, useRef, useState } from "react";
import Table from "../../components/table/Table";
import Badge from "../../components/badge/Badge";
import { getData, postData } from "../../authentication/axiosInstance";

const clickOutsideRef = (content_ref, toggle_ref) => {
  document.addEventListener("mousedown", (e) => {
    // user click toggle
    if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
      content_ref.current.classList.toggle("active");
    } else {
      // user click outside toggle and content
      if (content_ref.current && !content_ref.current.contains(e.target)) {
        content_ref.current.classList.remove("active");
      }
    }
  });
};

function Clients() {
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "admin",
    fb_page_id: "",
  });
  const [pagelist, setPageList] = useState([]);

  const getPageList = async () => {
    // try {
    //   const response = await getData("superadmin/page/list");
    //   if (response.success === "SUCCESS") {
    //   }
    // } catch (error) {
    //   console.log(error.response.data.message || error.message);
    // }

    try {
      const response = await getData("superadmin/page/list");
      console.log("page list", response);
      if (response.success === "SUCCESS") {
        setPageList(response.data);
      }
    } catch (error) {
      console.log(error.response.data.message || error.message);
    }
  };

  useEffect(() => {
    getPageList();
  }, []);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("superadmin/register", formData);
      console.log("Clients result", response);
      if (response.success === "SUCCESS") {
        closeMenu();
        setFormData({
          first_name: "",
          last_name: "",
          email: "",
          password: "",
          role: "admin",
          fb_page_id: "",
        });
      }
    } catch (error) {
      console.log("error", error.response.data.message || error.message);
      alert(error.response.data.message || error.message);
    }
  };

  const latestOrders = {
    header: ["order id", "user", "total price", "date", "status"],
    body: [
      {
        id: "#OD1711",
        user: "Praveen ",
        date: "17 Jun 2021",
        price: "900",
        status: "shipping",
      },
      {
        id: "#OD1712",
        user: "frank iva",
        date: "1 Jun 2021",
        price: "400",
        status: "paid",
      },
      {
        id: "#OD1713",
        user: "anthony baker",
        date: "27 Jun 2021",
        price: "200",
        status: "pending",
      },
      {
        id: "#OD1714",
        user: "alice",
        date: "5 Jul 2021",
        price: "500",
        status: "refund",
      },
      {
        id: "#OD1711",
        user: "john doe",
        date: "17 Jun 2021",
        price: "900",
        status: "shipping",
      },
      {
        id: "#OD1712",
        user: "frank iva",
        date: "1 Jun 2021",
        price: "400",
        status: "paid",
      },
      {
        id: "#OD1713",
        user: "anthony baker",
        date: "27 Jun 2021",
        price: "200",
        status: "pending",
      },
      {
        id: "#OD1714",
        user: "alice",
        date: "5 Jul 2021",
        price: "500",
        status: "refund",
      },
      {
        id: "#OD1711",
        user: "john doe",
        date: "17 Jun 2021",
        price: "900",
        status: "shipping",
      },
      {
        id: "#OD1712",
        user: "frank iva",
        date: "1 Jun 2021",
        price: "400",
        status: "paid",
      },
      {
        id: "#OD1713",
        user: "anthony baker",
        date: "27 Jun 2021",
        price: "200",
        status: "pending",
      },
      {
        id: "#OD1714",
        user: "alice",
        date: "5 Jul 2021",
        price: "500",
        status: "refund",
      },
      {
        id: "#OD1711",
        user: "john doe",
        date: "17 Jun 2021",
        price: "900",
        status: "shipping",
      },
      {
        id: "#OD1712",
        user: "frank iva",
        date: "1 Jun 2021",
        price: "400",
        status: "paid",
      },
      {
        id: "#OD1713",
        user: "anthony baker",
        date: "27 Jun 2021",
        price: "200",
        status: "pending",
      },
      {
        id: "#OD1714",
        user: "alice",
        date: "5 Jul 2021",
        price: "500",
        status: "refund",
      },
      {
        id: "#OD1711",
        user: "john doe",
        date: "17 Jun 2021",
        price: "900",
        status: "shipping",
      },
      {
        id: "#OD1712",
        user: "frank iva",
        date: "1 Jun 2021",
        price: "400",
        status: "paid",
      },
      {
        id: "#OD1713",
        user: "anthony baker",
        date: "27 Jun 2021",
        price: "200",
        status: "pending",
      },
      {
        id: "#OD1712",
        user: "frank iva",
        date: "1 Jun 2021",
        price: "400",
        status: "paid",
      },
      {
        id: "#OD1713",
        user: "anthony baker",
        date: "27 Jun 2021",
        price: "200",
        status: "refund",
      },
    ],
  };

  const orderStatus = {
    shipping: "primary",
    pending: "warning",
    paid: "success",
    refund: "danger",
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage] = useState(10);
  const [sortedData, setSortedData] = useState(latestOrders.body);
  const [sortField, setSortField] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");

  const menu_ref = useRef(null);
  const menu_toggle_ref = useRef(null);

  clickOutsideRef(menu_ref, menu_toggle_ref);

  const setActiveMenu = () => menu_ref.current.classList.add("active");

  const closeMenu = () => menu_ref.current.classList.remove("active");

  const headerToKey = {
    "order id": "id",
    user: "user",
    "total price": "price", // Ensure this matches your data key
    date: "date",
    status: "status",
  };
  const renderOrderHead = (item, index) => (
    <th key={index} onClick={() => handleSort(headerToKey[item])}>
      {item}
      {sortField === headerToKey[item] ? (sortOrder === "asc" ? "▲" : "▼") : ""}
    </th>
  );

  const renderOrderBody = (item, index) => (
    <tr key={index}>
      <td>{item.id}</td>
      <td>{item.user}</td>
      <td>{item.price}</td>
      <td>{item.date}</td>
      <td>
        <Badge type={orderStatus[item.status]} content={item.status} />
      </td>
    </tr>
  );

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    const sorted = [...latestOrders.body].sort((a, b) => {
      if (field === "price") {
        const aPrice = parseFloat(a[field]);
        const bPrice = parseFloat(b[field]);
        if (isNaN(aPrice) || isNaN(bPrice)) return 0;
        return order === "asc" ? aPrice - bPrice : bPrice - aPrice;
      }
      if (a[field] > b[field]) return order === "asc" ? 1 : -1;
      if (a[field] < b[field]) return order === "asc" ? -1 : 1;
      return 0;
    });
    setSortField(field);
    setSortOrder(order);
    setSortedData(sorted);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const indexOfLastLead = currentPage * leadsPerPage;
  const indexOfFirstLead = indexOfLastLead - leadsPerPage;
  const currentLeads = sortedData.slice(indexOfFirstLead, indexOfLastLead);
  const totalPages = Math.ceil(sortedData.length / leadsPerPage);

  return (
    <div>
      {console.log("current", currentLeads)}
      <div className="col-12">
        <button
          ref={menu_toggle_ref}
          className="actualbutton"
          onClick={() => setActiveMenu()}
        >
          Add Client
        </button>
        <div ref={menu_ref} className="theme-menu">
          <h2 style={{ marginBottom: 30 }}>Add Client</h2>
          <button className="theme-menu__close" onClick={() => closeMenu()}>
            <i className="bx bx-x"></i>
          </button>
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              autoComplete="new-password"
              style={{ display: "none" }}
            />
            <input
              type="email"
              autoComplete="custom-email"
              style={{ display: "none" }}
            />
            <div className="inputsection">
              <label>First Name</label>
              <input
                className="input"
                type="text"
                name="first_name"
                value={formData.first_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="inputsection">
              <label>Last Name</label>
              <input
                className="input"
                type="text"
                name="last_name"
                value={formData.last_name}
                onChange={handleChange}
                required
              />
            </div>

            <div className="inputsection">
              <label>Email</label>
              <input
                className="input"
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>

            <div className="inputsection">
              <label>Password</label>
              <input
                className="input"
                type="password"
                name="password"
                value={formData.password}
                onChange={handleChange}
                required
                autoComplete="off"
              />
            </div>

            <div className="inputsection">
              <label>Role</label>
              <select
                className="input"
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  width: "100%",
                  backgroundColor: "white",
                  marginBottom: 10,
                }}
              >
                <option value="admin">Admin</option>
                <option value=""></option>
              </select>
            </div>
            <div className="inputsection">
              <label>Facebook Page ID</label>
              <select
                className="input"
                name="fb_page_id"
                value={formData.fb_page_id}
                onChange={handleChange}
                required
                style={{
                  padding: "10px",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  width: "100%",
                  backgroundColor: "white",
                  marginBottom: 10,
                }}
              >
                <option value="">Select Page</option>
                {pagelist?.map((item, index) => (
                  <option value={item.page_id} key={index}>
                    {item.name}
                  </option>
                ))}
              </select>
            </div>

            <button
              className="actualbutton"
              type="submit"
              style={{
                marginLeft: "10%",
                color: "white",
                backgroundColor: "#007dfe",
              }}
            >
              Add Client
            </button>
          </form>
        </div>

        <div className="card">
          <div className="card__header">
            <h3>Clients</h3>
          </div>
          <div className="card__body">
            <Table
              key={currentPage}
              headData={latestOrders.header}
              renderHead={(item, index) => renderOrderHead(item, index)}
              bodyData={currentLeads}
              renderBody={(item, index) => renderOrderBody(item, index)}
            />
          </div>
        </div>
        {/* Pagination controls */}
        <div className="pagination-container">
          <button
            className="pagination-btn"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            ←
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`pagination-btn ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="pagination-btn"
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            →
          </button>
        </div>
      </div>
    </div>
  );
}

export default Clients;
