// src/axiosInstance.js
import axios from "axios";
import { getToken, removeToken } from "./auth"; // Removed unused getUserData

const Base_url = process.env.REACT_APP_BASE_URL;

const axiosInstance = axios.create({
  baseURL: Base_url, // No need to append this in each API call
});

// Add Authorization header with token in all requests if available
axiosInstance.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Handle 401 error and redirect to login if token is invalid/expired
axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;
    if (
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      removeToken(); // Remove token on unauthorized
      window.location.href = "/"; // Redirect to login page
    }
    return Promise.reject(error);
  }
);

export default axiosInstance;

// You no longer need to pass headers in each request because the interceptor handles it

export const getData = async (url) => {
  try {
    const response = await axiosInstance.get(url);
    return response.data;
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
};

export const postData = async (url, payload) => {
  try {
    const response = await axiosInstance.post(url, JSON.stringify(payload), {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error("Error posting data:", error);
    throw error;
  }
};

export const putData = async (url, payload) => {
  try {
    const response = await axiosInstance.put(url, payload, {
      headers: { "Content-Type": "application/json" },
    });
    return response.data;
  } catch (error) {
    console.error("Error putting data:", error);
    throw error;
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosInstance.delete(url);
    return response.data;
  } catch (error) {
    console.error("Error deleting data:", error);
    throw error;
  }
};
