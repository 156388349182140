import React, { useState } from "react";
import { postData } from "../authentication/axiosInstance";

const ForgetPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("auth/forget-password", { email: email });
      console.log("forgetpassword response", response);
      if (response.success === "SUCCESS") {
        setMessage(response.message);
      }
    } catch (error) {
      console.log("error", error.response.data.message || error.message);
      alert(error.response.data.message || error.message);
    }
  };

  return (
    <div className="auth-layout">
      <div>
        <img
          src="https://img.freepik.com/free-vector/forgot-password-concept-illustration_114360-1123.jpg?t=st=1726128711~exp=1726132311~hmac=4f681b36a5bb8f106f8daa0378b7effad8e1d98e395f49b76e8b00710814790d&w=826"
          alt="forget-password"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "normal",
          height: 610,
          width: 400,
          flexDirection: "column",
        }}
      >
        <form onSubmit={handleSubmit} className="auth-form">
          <h2 style={{ textAlign: "center", marginBottom: 20 }}>
            Forget Password
          </h2>
          <div className="form-group">
            <label htmlFor="email">Enter your email</label>
            <input
              className="input"
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <button
            className="actualbutton"
            type="submit"
            style={{
              color: "white",
              backgroundColor: "#007dfe",
            }}
          >
            Send Reset Link
          </button>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: 10,
          }}
        >
          <a href="/">Go to Login →</a>
        </div>

        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default ForgetPassword;
