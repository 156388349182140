import React, { useState } from "react";
import { postData } from "../authentication/axiosInstance";
import { useHistory } from "react-router-dom";

const Register = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    role: "admin",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("auth/register", formData);
      console.log("register result", response);
      if (response.success === "SUCCESS") {
        history.push("/");
        setFormData({
          ...formData,
          first_name: "",
          last_name: "",
          email: "",
          password: "",
        });
      }
    } catch (error) {
      console.log("error", error.response.data.message || error.message);
      alert(error.response.data.message || error.message);
    }
  };

  return (
    <div className="auth-layout">
      <div>
        <img
          src="https://img.freepik.com/free-vector/sign-up-concept-illustration_114360-7965.jpg?t=st=1726128115~exp=1726131715~hmac=7742aa84f2eba131c0b39afad5509d77f50f8b7626dc30bcdeabc457f5c2cf8a&w=826"
          alt="forget-password"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "normal",
          height: 610,
          width: 400,
          flexDirection: "column",
        }}
      >
        <form onSubmit={handleSubmit}>
          <h2 style={{ textAlign: "center", marginBottom: 20 }}>Register</h2>
          <div>
            <label>First Name</label>
            <input
              className="input"
              type="text"
              name="first_name"
              value={formData.first_name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Last Name</label>
            <input
              className="input"
              type="text"
              name="last_name"
              value={formData.last_name}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Email</label>
            <input
              className="input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div>
            <label>Password</label>
            <input
              className="input"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button
            className="actualbutton"
            type="submit"
            style={{
              marginLeft: "25%",
              color: "white",
              backgroundColor: "#007dfe",
            }}
          >
            Register
          </button>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: 10,
          }}
        >
          <a href="/">Go to Login →</a>
        </div>
      </div>
    </div>
  );
};

export default Register;
