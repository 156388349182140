import React, { useEffect, useState } from "react";

import "./topnav.css";

import { Link } from "react-router-dom";

import Dropdown from "../dropdown/Dropdown";

import ThemeMenu from "../thememenu/ThemeMenu";

import notifications from "../../assets/JsonData/notification.json";

import user_menu from "../../assets/JsonData/user_menus.json";
import { getUserData } from "../../authentication/auth";
import { useHistory } from "react-router-dom";

const clearCookies = () => {
  const cookies = document.cookie.split("; ");
  for (let cookie of cookies) {
    const [name] = cookie.split("=");
    document.cookie = `${name}=; max-age=0; path=/;`;
  }
};

const handleLogout = (history) => {
  localStorage.clear();
  clearCookies();
  history.replace("/");
};

const renderNotificationItem = (item, index) => (
  <div className="notification-item" key={index}>
    <i className={item.icon}></i>
    <span>{item.content}</span>
  </div>
);

const renderUserToggle = (user) => (
  <div className="topnav__right-user">
    <div className="topnav__right-user__image">
      <img
        src="https://img.freepik.com/free-vector/blue-circle-with-white-user_78370-4707.jpg?t=st=1726218230~exp=1726221830~hmac=e10a62c345c5226df31473a7df8c8eaa544c6a034c8d31788797e321d48cecea&w=826"
        alt="Profile"
      />
    </div>
    <div className="topnav__right-user__name">
      {user.first_name} {user.last_name}
    </div>
  </div>
);

const renderUserMenu = (item, index, history) => {
  if (item.content === "Logout") {
    return (
      <div
        className="notification-item"
        key={index}
        onClick={() => handleLogout(history)}
        style={{ cursor: "pointer" }}
      >
        <i className={item.icon}></i>
        <span>{item.content}</span>
      </div>
    );
  }
  return (
    <Link to="/" key={index}>
      <div className="notification-item">
        <i className={item.icon}></i>
        <span>{item.content}</span>
      </div>
    </Link>
  );
};

const Topnav = () => {
  const history = useHistory();
  const [user, setUser] = useState("");
  useEffect(() => {
    let isMounted = true; // Flag to track if the component is mounted
    const getUser = async () => {
      const data = await getUserData("userdata");
      if (isMounted) {
        setUser(data);
      }
    };

    getUser();
    return () => {
      isMounted = false;
    };
  }, [user]);

  return (
    <div className="topnav">
      <div className="topnav__search">
        <input type="text" placeholder="Search here..." />
        <i className="bx bx-search"></i>
      </div>
      <div className="topnav__right">
        <div className="topnav__right-item">
          {/* dropdown here */}
          <Dropdown
            customToggle={() => renderUserToggle(user)}
            contentData={user_menu}
            renderItems={(item, index) => renderUserMenu(item, index, history)}
          />
        </div>
        <div className="topnav__right-item">
          <Dropdown
            icon="bx bx-bell"
            badge="12"
            contentData={notifications}
            renderItems={(item, index) => renderNotificationItem(item, index)}
          />
          {/* dropdown here */}
        </div>
        <div className="topnav__right-item">
          <ThemeMenu />
        </div>
      </div>
    </div>
  );
};

export default Topnav;
