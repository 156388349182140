import React, { useEffect, useState } from "react";

import { Link } from "react-router-dom";

import "./sidebar.css";

// import logo from "../../assets/images/logo.png";

import { sidebar_items, superadmin_sidebar_items } from "./sidebar_routes";
import { getUserData } from "../../authentication/auth";

const SidebarItem = (props) => {
  const active = props.active ? "active" : "";

  return (
    <div className="sidebar__item">
      <div className={`sidebar__item-inner ${active}`}>
        <i className={props.icon}></i>
        <span>{props.title}</span>
      </div>
    </div>
  );
};

const Sidebar = (props) => {
  const [sidebaroptions, setSidebaroptions] = useState([]);

  useEffect(() => {
    const setoptions = async () => {
      const role = await getUserData("userdata")?.role;
      if (role === "admin") {
        setSidebaroptions(sidebar_items);
      } else setSidebaroptions(superadmin_sidebar_items);
    };
    setoptions();
  }, []);
  const activeItem = sidebaroptions.findIndex(
    (item) => props.location.pathname === item.route
  );

  return (
    <div className="sidebar">
      <div className="sidebar__logo">
        <img
          src="https://img.freepik.com/free-vector/phoenix-logo-concept_23-2148477263.jpg?t=st=1726211725~exp=1726215325~hmac=2ab298742579ebbfd0497bcc5ddd8e6593684288f38d7b66559f6fe16fe30f4d&w=826"
          alt="company logo"
          style={{ width: 120, height: 120, borderRadius: 60 }}
        />
        {/* SureScanR */}
      </div>
      {sidebaroptions.map((item, index) => (
        <Link to={item.route} key={index}>
          <SidebarItem
            title={item.display_name}
            icon={item.icon}
            active={index === (activeItem === -1 ? 0 : activeItem)}
          />
        </Link>
      ))}
    </div>
  );
};

export default Sidebar;
