import React, { useState } from "react";
import { postData } from "../authentication/axiosInstance";
import { useHistory } from "react-router-dom";

const ResetPassword = () => {
  const history = useHistory();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password === confirmPassword) {
      try {
        const response = await postData(`auth/reset-password?token=`, {
          password: password,
          confirm_password: confirmPassword,
        });
        if (response.success === "SUCCESS") {
          setMessage("Your password has been successfully reset.");
          history.push("/");
        }
      } catch (error) {
        console.log("error", error.response.data.message || error.message);
        alert(error.response.data.message || error.message);
      }
    } else {
      setMessage("Passwords do not match.");
    }
  };

  return (
    <div className="auth-layout">
      <div>
        <img
          src="https://img.freepik.com/free-vector/reset-password-concept-illustration_114360-7966.jpg?t=st=1726128133~exp=1726131733~hmac=8635acf038eea9b8ff82d0f5957994c173341f0d5e51697d06e3735edcee6823&w=826"
          alt="Login"
        />
      </div>
      <div className="auth-page">
        <h2>Reset Password</h2>
        <form onSubmit={handleSubmit} className="auth-form">
          <div className="form-group">
            <label htmlFor="password">New Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="confirmPassword">Confirm Password</label>
            <input
              type="password"
              id="confirmPassword"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <button type="submit" className="btn btn-primary">
            Reset Password
          </button>
        </form>
        {message && <p className="message">{message}</p>}
      </div>
    </div>
  );
};

export default ResetPassword;
