export const saveUserData = (name, data) => {
  localStorage.setItem(`${name}`, JSON.stringify(data));
};
export const getUserData = (data) => {
  return JSON.parse(localStorage.getItem(`${data}`));
};

export const saveToken = (token) => {
  document.cookie = `token=${token}; path=/; secure; SameSite=Strict; max-age=${
    60 * 60 * 24 * 7
  }`; // expires in 7 days
};

// Get token from cookie
export const getToken = () => {
  const name = "token=";
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(";");
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

// Remove token from cookie
export const removeToken = () => {
  document.cookie = "token=; path=/; max-age=0";
};
