import React from "react";
import { Route, Switch } from "react-router-dom";

import Dashboard from "../pages/Dashboard";
import Customers from "../pages/Customers";
import Leads from "../pages/Leads";
import Login from "../pages/Login";
import Register from "../pages/Register";
import ForgetPassword from "../pages/ForgetPassword";
import ResetPassword from "../pages/ResetPassword";
//SuperAdmin routes
import SuperAdminDashboard from "../pages/supersdmin/SuperAdminDashboard";
import Clients from "../pages/supersdmin/Clients";
import LeadDetails from "../pages/LeadDetails";

const Routes = () => {
  return (
    <Switch>
      {/* Admin Routes */}
      <Route path="/" exact component={Login} />
      <Route path="/register" component={Register} />
      <Route path="/forgetPassword" component={ForgetPassword} />
      <Route path="/resetPassword" component={ResetPassword} />
      <Route path="/dashboard" component={Dashboard} />
      <Route path="/customers" component={Customers} />
      <Route path="/leads" component={Leads} />
      <Route path="/lead-details" component={LeadDetails} />
      {/* Superadmin Routes */}
      <Route path="/superadmin/dashboard" component={SuperAdminDashboard} />
      <Route path="/superadmin/clients" component={Clients} />
    </Switch>
  );
};

export default Routes;
