import React, { useState, useRef, useEffect } from "react";
import Table from "../components/table/Table";
import { postData, getData } from "../authentication/axiosInstance";

function Leads() {
  const [currentPage, setCurrentPage] = useState(1);
  const [leadsPerPage] = useState(8);
  const [sortedData, setSortedData] = useState([]);
  const [sortField, setSortField] = useState("");
  const [sortOrder, setSortOrder] = useState("asc");
  const [details, setDetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalrecords, setTotalRecords] = useState(null);
  const [campaignName, setCampaignName] = useState("");
  const [platform, setPlatform] = useState("");
  const [campaignlist, setCampaignlist] = useState([]);

  const getLead = async () => {
    const payload = {
      filter_by: {
        campaign_name: campaignName,
        platform: platform,
      },
      sort: sortField,
      sort_order: sortOrder,
      page: currentPage,
      limit: leadsPerPage,
    };
    console.log("payload", payload);
    try {
      const response = await postData("users/leads/list", payload);
      console.log("leads api response", response);

      if (response.success === "SUCCESS" && response.data.leads.length > 0) {
        const leadData = response?.data?.leads?.map((item) => {
          const fieldDataDetails = item?.fb_lead_details;
          const campaign = fieldDataDetails?.campaign_name;
          const platform = fieldDataDetails.platform;
          const fieldData = fieldDataDetails.field_data;
          const created_time = item?.created_time;
          const leadgen_id = item?.leadgen_id;

          const nameField = fieldData.find(
            (field) => field.name === "full_name"
          );
          const emailField = fieldData.find((field) => field.name === "email");
          const phoneField = fieldData.find(
            (field) => field.name === "phone_number"
          );

          // Using optional chaining and fallback values
          const name = nameField?.values?.[0] || "N/A";
          const email = emailField?.values?.[0] || "N/A";
          const phone = phoneField?.values?.[0] || "N/A";

          return {
            Campaign: campaign,
            Name: name,
            Email: email,
            Phone: phone,
            Platform: platform,
            Created_time: created_time,
            Leadgen_id: leadgen_id,
          };
        });
        const list = response?.data?.campaignList.map((item) => item._id);
        console.log("leadData", leadData, list);
        setSortedData(leadData);
        setCampaignlist(list);
        setTotalRecords(response.data?.totalRecords || 0);
      }
    } catch (error) {
      console.log("error", error.response?.data.message || error.message);
    }
  };

  useEffect(() => {
    setSortedData([]);
    getLead();
  }, [currentPage, sortOrder, sortField, platform, campaignName]);

  const clickOutsideRef = (content_ref, toggle_ref) => {
    document.addEventListener("mousedown", (e) => {
      if (toggle_ref.current && toggle_ref.current.contains(e.target)) {
        content_ref.current.classList.toggle("active");
      } else {
        if (content_ref.current && !content_ref.current.contains(e.target)) {
          content_ref.current.classList.remove("active");
        }
      }
    });
  };
  const menu_ref = useRef(null);
  const menu_toggle_ref = useRef(null);
  clickOutsideRef(menu_ref, menu_toggle_ref);

  const setActiveMenu = async (leadgen_id) => {
    setLoading(true); // Set loading to true before the API call
    try {
      const response = await getData(`users/leads/${leadgen_id}`);
      console.log("lead details with id", response.data);
      if (response.success === "SUCCESS") {
        setDetails(response.data);
      }
    } catch (error) {
      console.log("error", error.response?.data.message || error.message);
    } finally {
      setLoading(false);
      menu_ref.current.classList.add("active");
    }
  };

  const closeMenu = () => {
    menu_ref.current.classList.remove("active");
    setDetails(null); // Clear details when closing the menu
  };

  const keyhead = {
    "Campaign Name": "campaign_name",
    Platform: "platform",
    Created_time: "created_time",
  };

  const handleSort = (field) => {
    const order = sortField === field && sortOrder === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortOrder(order);
  };

  const renderOrderHead = (item, index) => (
    <th key={index} onClick={() => handleSort(keyhead[item])}>
      {item}
      {sortField === keyhead[item] ? (sortOrder === "asc" ? "▲" : "▼") : ""}
    </th>
  );

  const inIST = (utcDateString) => {
    const utcDate = new Date(utcDateString);
    return utcDate.toLocaleString("en-IN", { timeZone: "Asia/Kolkata" });
  };

  const renderOrderBody = (item, index) => (
    <tr key={index} onClick={() => setActiveMenu(item.Leadgen_id)}>
      <td>{item.Campaign}</td>
      <td>{item.Name}</td>
      <td>{item.Email}</td>
      <td>{item.Phone}</td>
      <td>
        {(item.Platform === "fb" || item.Platform === "ig") && "Facebook"}
      </td>
      <td>{inIST(item.Created_time)}</td> {/* Format the time correctly */}
    </tr>
  );

  const paginate = (pageNumber) => {
    if (pageNumber >= 1 && pageNumber <= totalPages) {
      console.log("Setting page to:", pageNumber);
      setCurrentPage(pageNumber); // This should trigger a re-render
    }
  };

  const totalPages = Math.ceil(totalrecords / leadsPerPage);

  return (
    <div>
      <div className="col-12">
        {/* <button
          ref={menu_toggle_ref}
          className="actualbutton"
          onClick={() => setActiveMenu()}
        >
          Add Lead
        </button> */}
        <div ref={menu_ref} className="theme-menu">
          <h2 style={{ marginBottom: 30 }}>Lead Details</h2>
          <button className="theme-menu__close" onClick={closeMenu}>
            <i className="bx bx-x"></i>
          </button>
          {loading ? (
            <p>Loading...</p>
          ) : details ? (
            <div>
              <p>
                <strong>Full Name:</strong>
              </p>
              <input
                value={
                  details?.field_data
                    ?.filter((item) => item.name === "full_name")
                    ?.map((filteredItem) => filteredItem.values[0]) || ""
                }
              />

              <p>
                <strong>Email:</strong>
              </p>
              <input
                value={
                  details?.field_data
                    ?.filter((item) => item.name === "email")
                    ?.map((filteredItem) => filteredItem.values[0]) || ""
                }
              />

              <p>
                <strong>Phone:</strong>
              </p>
              <input
                value={
                  details?.field_data
                    ?.filter((item) => item.name === "phone_number")
                    .map((filteredItem) => filteredItem.values[0]) || ""
                }
              />

              <p>
                <strong>Revenue:</strong>
              </p>
              <input
                value={
                  details?.field_data
                    ?.filter(
                      (item) => item.name === "what_is_your_monthly_revenue?"
                    )
                    .map((filteredItem) =>
                      filteredItem.values[0].split("_").join(" ")
                    ) || ""
                }
              />

              <p>
                <strong>Marketing Budget:</strong>
              </p>
              <input
                value={
                  details?.field_data
                    ?.filter(
                      (item) =>
                        item.name ===
                        "what_is_your_monthly_paid_marketing_budget?"
                    )
                    .map((filteredItem) =>
                      filteredItem.values[0].split("_").join(" ")
                    ) || ""
                }
              />

              <p>
                <strong>Location:</strong>
              </p>
              <input
                value={details?.field_data
                  ?.filter(
                    (item) => item.name === "are_you_located_in_gurgaon?"
                  )
                  .map(
                    (filteredItem) =>
                      filteredItem.values[0] === "yes_" && "Gurugram"
                  )}
              />
              <p>
                <strong>Zip Code:</strong>
              </p>
              <input value={details.field_data?.[6].values?.[0]} />
              <p>
                <strong>Campaign Name:</strong>
              </p>
              <input value={details.campaign_name} />

              <p>
                <strong>Platform:</strong>
              </p>
              <input
                value={(details.platform === "ig" || "fb") && "Facebook"}
              />
              <p>
                <strong>Is Organic:</strong>
              </p>
              <input value={details.is_organic === false ? "False" : "True"} />
            </div>
          ) : (
            <p>No details available.</p>
          )}
        </div>
        <div className="card">
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div className="card__header">
              <h3>Latest Leads</h3>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                columnGap: 10,
              }}
            >
              <div className="inputsection">
                <h4>Campaign Name</h4>
                <select
                  className="input"
                  value={campaignName}
                  onChange={(e) => setCampaignName(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid grey",
                    width: "100%",
                    backgroundColor: "white",
                    marginBottom: 10,
                  }}
                >
                  <option value="">All</option>
                  {campaignlist.map(
                    (item, index) => (
                      console.log("item", item),
                      (
                        <option value={item} key={index}>
                          {item}
                        </option>
                      )
                    )
                  )}
                </select>
              </div>
              <div className="inputsection">
                <h4>Platform</h4>
                <select
                  className="input"
                  value={platform}
                  onChange={(e) => setPlatform(e.target.value)}
                  required
                  style={{
                    padding: "10px",
                    borderRadius: "10px",
                    border: "2px solid grey",
                    width: "100%",
                    backgroundColor: "white",
                    marginBottom: 10,
                  }}
                >
                  <option value="">All</option>
                  <option value="ig">Instagram</option>
                  <option value="fb">Facebook</option>
                </select>
              </div>
              <button
                className="actualbutton"
                style={{
                  color: "white",
                  backgroundColor: "#007dfe",
                }}
                onClick={() => {
                  setCampaignName("");
                  setPlatform("");
                  setSortField("");
                  setSortOrder("asc");
                }}
              >
                Reset filters
              </button>
            </div>
          </div>

          <div className="card__body">
            <Table
              key={
                sortedData.length === leadsPerPage ? currentPage : sortedData
              }
              headData={[
                "Campaign Name",
                "Name",
                "Email",
                "Phone",
                "Platform",
                "Created_time",
              ]}
              renderHead={(item, index) => renderOrderHead(item, index)}
              bodyData={sortedData.length > 0 ? sortedData : []}
              renderBody={(item, index) => renderOrderBody(item, index)}
            />
          </div>
        </div>
        {/* Pagination controls */}

        <div className="pagination-container">
          <button
            className="pagination-btn"
            onClick={() => paginate(currentPage - 1)}
            disabled={currentPage === 1}
          >
            ←
          </button>
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index}
              onClick={() => paginate(index + 1)}
              className={`pagination-btn ${
                currentPage === index + 1 ? "active" : ""
              }`}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="pagination-btn"
            onClick={() => paginate(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            →
          </button>
        </div>
      </div>
    </div>
  );
}

export default Leads;
