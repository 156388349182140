export const sidebar_items = [
  {
    display_name: "Dashboard",
    route: "/dashboard",
    icon: "bx bxs-dashboard",
  },
  // {
  //   display_name: "Super Admin",
  //   route: "/superadmin",
  //   icon: "bx bxs-user-rectangle",
  // },
  {
    display_name: "Lead",
    route: "/leads",
    icon: "bx bxs-id-card",
  },
  {
    display_name: "Companies",
    route: "/Companies",
    icon: "bx bxs-buildings",
  },
  {
    display_name: "Users",
    route: "/Users",
    icon: "bx bxs-user-account",
  },
  {
    display_name: "New Entries",
    route: "/NewEntries",
    icon: "bx bxs-message-alt-add",
  },
  {
    display_name: "View Entries",
    route: "/ViewEntries",
    icon: "bx bxs-file-find",
  },
  {
    display_name: "Radar Chart",
    route: "/RadarChart",
    icon: "bx bx-radar",
  },
  {
    display_name: "ScoreBoard",
    route: "/ScoreBoard",
    icon: "bx bx-clipboard",
  },
];

export const superadmin_sidebar_items = [
  {
    display_name: "SuperAdmin Dashboard",
    route: "/superadmin/dashboard",
    icon: "bx bxs-dashboard",
  },
  {
    display_name: "SuperAdmin Clients",
    route: "/superadmin/clients",
    icon: "bx bxs-user-rectangle",
  },
];
