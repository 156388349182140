import React, { useState } from "react";
import { postData } from "../authentication/axiosInstance";
import { useHistory } from "react-router-dom";
import { saveToken, saveUserData } from "../authentication/auth";

// import logo from "../assets/images/logo.png";

const Login = () => {
  const history = useHistory();
  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await postData("auth/login", formData);
      const { token, data } = response; // Assuming the API returns a token in response
      if (response.success === "SUCCESS") {
        saveToken(token);
        saveUserData("userdata", data);
        if (data?.role === "admin") {
          history.push("/dashboard");
        } else {
          history.push("/superadmin-dashboard");
        }
      }
    } catch (error) {
      console.log("error", error.response.data.message || error.message);
      alert(error.response.data.message || error.message);
    }
  };

  return (
    <div className="auth-layout">
      <div>
        <img
          src="https://img.freepik.com/free-vector/login-concept-illustration_114360-757.jpg?t=st=1726128184~exp=1726131784~hmac=d53a5f531a21927eff783ebeb48173e90907d944c8e7d41615e5b36a70ac3c78&w=826"
          alt="Login"
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "normal",
          height: 610,
          width: 400,
          flexDirection: "column",
        }}
      >
        <form onSubmit={handleSubmit}>
          {/* <img
            src={logo}
            alt="company logo"
            style={{ width: 400, marginBottom: 100, marginTop: -100 }}
          /> */}
          <h2 style={{ textAlign: "center", marginBottom: 20 }}>Login</h2>

          <div className="inputsection">
            <label>Email</label>
            <input
              className="input"
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="inputsection">
            <label>Password</label>
            <input
              className="input"
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </div>
          <button
            className="actualbutton"
            type="submit"
            style={{
              marginLeft: "23%",
              color: "white",
              backgroundColor: "#007dfe",
            }}
          >
            Login
          </button>
        </form>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            rowGap: 10,
          }}
        >
          <a href="/register">Register Now!</a>
          <a href="/forgetPassword">Forget Password</a>
        </div>
      </div>
    </div>
  );
};

export default Login;
